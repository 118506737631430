<div id="firebaseui-auth-container"></div>

<input type="image" src="../assets/img/portada.JPG" [routerLink]="['/pedidos']" id="portada"/>
<div class="card" style="margin-bottom: 20px;">
  <h5 class="card-header"  style="background-color: #a5b368; text-align: center; "><b>{{ 'MENU.lbl_card_title' | translate }}</b></h5>
  <div class="card-body">
    <h5 class="card-title" style="color: #465019;"><b>{{ 'MENU.lbl_card_subtitle' | translate }}</b></h5>
    <p class="card-text" style="color: #465019;">{{ 'MENU.lbl_card_description' | translate }}</p>

    <a routerLink="/pedidos"   class="btn btn-outline-success btn-lg " style="color:#198754; font-weight: bold;display: flex;
  justify-content: center; ">Pedidos</a>
  </div>
</div>

<ng-image-slider [images]="imageObject" #nav [infinite]=true [autoSlide]=2></ng-image-slider>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Elaborado } from './elaborado';
import { ElaboradosComponent } from './elaborados.component';
import { Aceituna } from '../aceitunas/aceituna';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  public href: string = "";
  public mostrar:boolean=false;
  public _elaborados:Elaborado[]=[

    {id:1,nombre:"Croquetas de bacalao",descripcion:"Croquetas realizadas con nuestro mejor bacalao, totalmente caseras. Listas para freír a fuego medio-alto durante 2-3 minutos", foto:"../assets/img/croquetas.jpeg"},
    {id:2,nombre:"Bacalao con sanfaina",descripcion:"Bacalao en sanfaina casero listo para comer con un golpe de microondas", foto:"foto"},
    {id:3,nombre:"Bacalao a la llauna",descripcion:"Nuestro lomo de bacalao rebozado y acompañado de pimentón y ajo. Listo para comer calentando al microondas. Se puede acompañar con alubias.", foto:"foto"},
    {id:4,nombre:"Esqueixada",descripcion:"", foto:"foto"},
    {id:5,nombre:"Boquerones en vinagre",descripcion:"Boquerones de mercado elaborados con paciencia, agua, sal y vinagre", foto:'../assets/img/boquerones.jpg'},
    {id:6,nombre:"Salpicón",descripcion:"", foto:"foto"},
    {id:7,nombre:"Escalivada",descripcion:"", foto:"foto"},
    {id:8,nombre:"Filete rebozado",descripcion:"", foto:"foto"},
    {id:9,nombre:"Banderillas",descripcion:"", foto:"foto"}

  ];

  public aceitunas:Aceituna[]=[
    {id:100, nombre: 'De la abuela', descripcion:'De sabor especialmente intenso y dulce, de carne firme y enteras, ligeramente machacadas y aliñadas con especias naturales. Son originarias de Antequera.',origen:'../assets/img/Andalucia.png',foto:'../assets/img/abuela.jpg'},
    {id:101, nombre: 'Sabor anchoa', descripcion:'De origen sevillano, es la variedad más famosa por su versatilidad y sabor equilibrado. Puede acompañar tanto aperitivos como ensaladas.',origen:'../assets/img/Andalucia.png',foto:'../assets/img/anchoa.jpg'},
    {id:102, nombre: 'Manzanilla', descripcion:'Con un sabor más suave que la sabor anchoa.',origen:'../assets/img/Andalucia.png',foto:'../assets/img/manzanilla.jpg'},
    {id:103, nombre: 'Mojo picón', descripcion:'Aceituna manzanilla aderezada con un caldo picante compuesto de guindilla, ajo y pimentón.',origen:'../assets/img/Canary.png',foto:'../assets/img/mojo.jpg'},
    {id:104, nombre: 'Salsa mojo picón', descripcion:'Aderezada con salsa fuertemente picante compuesta por ajo, pimienta picona, comino y pimentón. Tiene influencias de la gastronomía latinoamericana.',origen:'../assets/img/Canary.png',foto:'../assets/img/salsa.jpg'},
    {id:105, nombre: 'De Aragón', descripcion:'Aceituna negra de Aragón con vinagre, aceite de oliva virgen y sal.',origen:'../assets/img/Aragon.png',foto:'../assets/img/AcAragon.jpg'},
    {id:106, nombre: 'Arbequina', descripcion:'Originaria de Palestina. Fue importada en el siglo XVII. Es ideal como aperitivo.',origen:'../assets/img/Catalonia.png',foto:'../assets/img/arbequinas.jpg'},
    {id:107, nombre: 'De Jaén', descripcion:'Aceituna partida con aderezo de romero y tomillo con el que se consigue un sabor ligeramente amargo',origen:'../assets/img/Andalucia.png',foto:'../assets/img/acjaen.jpg'}

  ];

      constructor(private router: Router) {

      }

      ngOnInit() {
          this.href = this.router.url;
          console.log(this.router.url);

      }

      envases(){
        if (this.mostrar==false){
          this.mostrar=true;
        }else{
          this.mostrar=false;
        }

      }


}

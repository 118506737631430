import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public mostrar:boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

  envases(){
    if (this.mostrar==false){
      this.mostrar=true;
    }else{
      this.mostrar=false;
    }

  }


}

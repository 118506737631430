<div style="vertical-align:middle;color: #666633; text-align: center;  ">
  {{ 'CONSERVAS.conserva_1' | translate }}
</div>

<br>
  <ul style="vertical-align:middle;color: #666633;  margin-right: auto; margin-left: auto; ">
    <li>{{ 'CONSERVAS.conserva_2' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_3' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_4' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_5' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_6' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_7' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_8' | translate }}</li>
    <li>{{ 'CONSERVAS.conserva_9' | translate }}</li>

  </ul>
<br>
<br>

<table  style="text-align: center;">
  <tbody>
      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_croquetas' | translate }}</td>
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_sanfaina' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/croquetas.JPG"  alt="imagen" [routerLink]= "['/detalle',1]" id="imagen"></td>
        <td><input type="image" src="../assets/img/sanfaina.jpg" alt="imagen" [routerLink]= "['/detalle',2]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',1]" style="margin-bottom:5px">Ampliar</button></td>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',2]" style="margin-bottom:5px">Ampliar</button></td>
      </tr>

      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_llauna' | translate }}</td>
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_esqueixada' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/llauna.JPG" alt="imagen" [routerLink]= "['/detalle',3]" id="imagen"></td>
        <td><input type="image" src="../assets/img/esqueixada.JPG" alt="imagen" [routerLink]= "['/detalle',4]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',3]" style="margin-bottom:5px">Ampliar</button></td>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',4]" style="margin-bottom:5px">Ampliar</button></td>
      </tr>

      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_boqueron' | translate }}</td>
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_salpicon' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/boquerones.jpg" alt="imagen" [routerLink]= "['/detalle',5]" id="imagen"></td>
        <td><input type="image" src="../assets/img/salpicon.JPG" alt="imagen" [routerLink]= "['/detalle',6]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',5]" style="margin-bottom:5px">Ampliar</button></td>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',6]" style="margin-bottom:5px">Ampliar</button></td>
      </tr>

      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_escalivada' | translate }}</td>
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_bunuelos' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/escalivada.JPG" alt="imagen" [routerLink]= "['/detalle',7]" id="imagen"></td>
        <td><input type="image" src="../assets/img/bunuelos.JPG" alt="imagen" [routerLink]= "['/detalle',11]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',7]" style="margin-bottom:5px">Ampliar</button></td>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',8]" style="margin-bottom:5px">Ampliar</button></td>
      </tr>

      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_banderillas' | translate }}</td>
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_anchoas' | translate }}</td>
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/banderillas.jpg" alt="imagen" [routerLink]= "['/detalle',9]" id="imagen"></td>
        <td><input type="image" src="../assets/img/anchoas.JPG" alt="imagen" [routerLink]= "['/detalle',10]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',9]" style="margin-bottom:5px">Ampliar</button></td>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',10]" style="margin-bottom:5px">Ampliar</button></td>
      </tr>

      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_carpa' | translate }}</td>
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ELABORADOS.lbl_ensala' | translate }}</td>
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/carpa.JPG" alt="imagen" [routerLink]= "['/detalle',12]" id="imagen"></td>
        <td><input type="image" src="../assets/img/ensaladilla.JPG" alt="imagen" [routerLink]= "['/detalle',13]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',12]" style="margin-bottom:5px">Ampliar</button></td>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',13]" style="margin-bottom:5px">Ampliar</button></td>
      </tr>



  </tbody>
</table>

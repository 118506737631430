import {Component} from '@angular/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls:['./header.component.css']

})

export class HeaderComponent {
  title:string = 'Pesca Salada Pepi'


}

import { Component, OnInit } from '@angular/core';
import { Aceituna } from './aceituna';

@Component({
  selector: 'app-aceitunas',
  templateUrl: './aceitunas.component.html',
  styleUrls: ['./aceitunas.component.css']
})
export class AceitunasComponent implements OnInit {

  public aceitunas:Aceituna[]=[
    {id:100, nombre: 'De la abuela', descripcion:'De sabor especialmente intenso y dulce, de carne firme y enteras, ligeramente machacadas y aliñadas con especias naturales. Son originarias de Antequera.',origen:'../assets/img/Andalucia.png',foto:'../assets/img/abuela.jpg'},
    {id:101, nombre: 'Sabor anchoa', descripcion:'De origen sevillano, es la variedad más famosa por su versatilidad y sabor equilibrado. Puede acompañar tanto aperitivos como ensaladas.',origen:'../assets/img/Andalucia.png',foto:'../assets/img/anchoa.jpg'},
    {id:102, nombre: 'Manzanilla', descripcion:'Con un sabor más suave que la sabor anchoa.',origen:'../assets/img/Andalucia.png',foto:'../assets/img/manzanilla.jpg'},
    {id:103, nombre: 'Mojo picón', descripcion:'Aceituna manzanilla aderezada con un caldo picante compuesto de guindilla, ajo y pimentón.',origen:'../assets/img/Canary.png',foto:'../assets/img/mojo.jpg'},
    {id:104, nombre: 'Salsa mojo picón', descripcion:'Aderezada con salsa fuertemente picante compuesta por ajo, pimienta picona, comino y pimentón. Tiene influencias de la gastronomía latinoamericana.',origen:'../assets/img/Canary.png',foto:'../assets/img/salsa.jpg'},
    {id:105, nombre: 'De Aragón', descripcion:'Aceituna negra de Aragón con vinagre, aceite de oliva virgen y sal.',origen:'../assets/img/Aragon.png',foto:'../assets/img/AcAragon.jpg'},
    {id:106, nombre: 'Arbequina', descripcion:'Originaria de Palestina. Fue importada en el siglo XVII. Es ideal como aperitivo.',origen:'../assets/img/Catalonia.png',foto:'../assets/img/arbequinas.jpg'},
    {id:107, nombre: 'De Jaén', descripcion:'Aceituna partida con aderezo de romero y tomillo con el que se consigue un sabor ligeramente amargo',origen:'../assets/img/Andalucia.png',foto:'../assets/img/acjaen.jpg'}

  ];

  constructor() {

  }

  ngOnInit(): void {



  }

}

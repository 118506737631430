

<table  style=" text-align: center; ;margin-left:auto;margin-right:auto;">
  <tr>
    <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold;height:100px;width:200px">{{ 'SALAZONES.salazon_1' | translate }}</td>
    <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; height:100px;width:200px">{{ 'SALAZONES.salazon_2' | translate }}</td>
    <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold;height:100px;width:200px ">{{ 'SALAZONES.salazon_3' | translate }}</td>
    <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; height:100px;width:200px">{{ 'SALAZONES.salazon_4' | translate }} </td>

  </tr>

</table>
<br>
<table  style=" text-align: center;">

  <tbody>

      <tr>
        <td><img src="../assets/img/mojama.jpg" alt="imagen" ></td>
        <td><img  src="../assets/img/salmon.jpg" alt="imagen" ></td>
      </tr>
      <tr>
        <td><img src="../assets/img/arenques.jpg" alt="imagen" ></td>
        <td><img  src="../assets/img/lacosta.jpg" alt="imagen" ></td>
      </tr>
  </tbody>
</table>

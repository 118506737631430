
<table  style=" text-align: center;">
  <!--  <thead style="border: 1pt solid #c8e6c9;" >
      <tr >
        <!--149. Ocultando botones en las plantillas según el role del usuario

        <th style="color:#4b830d;">{{ 'MENU.lbl_nombre' | translate }}</th>
        <th style="color:#4b830d;"></th>
        <th style="color:#4b830d;">Origen</th>
      </tr>
    </thead>-->

  <tbody>
    <!--<tr *ngFor= "let aceituna of aceitunas">


      <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">{{aceituna.nombre}}</td>
      <td>
        <div style="vertical-align:middle; color: #666633; text-align: center;"><img [src]='aceituna.foto' alt="imagen"></div>
        <div><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',aceituna.id]" style="margin-bottom:5px;">Ampliar</button></div>
      </td>


      <td style="vertical-align:middle;color: #666633; text-align: center; padding:10px"><img [src]='aceituna.origen' alt="imagen"></td>-->
      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_abuela' | translate }}</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_anchoa' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>

      <tr>
        <td><input type="image" src="../assets/img/abuela.jpg" alt="imagen" [routerLink]= "['/detalle',100]" id="imagen"></td>


        <td><input type="image" src="../assets/img/anchoa.jpg" alt="imagen" [routerLink]= "['/detalle',101]" id="imagen" ></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',100]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',101]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>


  </tbody>
</table>
<table  style=" text-align: center;">
  <!--  <thead style="border: 1pt solid #c8e6c9;" >
      <tr >
        <!--149. Ocultando botones en las plantillas según el role del usuario

        <th style="color:#4b830d;">{{ 'MENU.lbl_nombre' | translate }}</th>
        <th style="color:#4b830d;"></th>
        <th style="color:#4b830d;">Origen</th>
      </tr>
    </thead>-->

  <tbody>
    <!--<tr *ngFor= "let aceituna of aceitunas">


      <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">{{aceituna.nombre}}</td>
      <td>
        <div style="vertical-align:middle; color: #666633; text-align: center;"><img [src]='aceituna.foto' alt="imagen"></div>
        <div><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',aceituna.id]" style="margin-bottom:5px;">Ampliar</button></div>
      </td>


      <td style="vertical-align:middle;color: #666633; text-align: center; padding:10px"><img [src]='aceituna.origen' alt="imagen"></td>-->


      <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_coctel' | translate }}</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_mojo' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td><input type="image" src="../assets/img/coctel.JPG" alt="imagen" [routerLink]= "['/detalle',111]" id="imagen"></td>
        <td><input type="image" src="../assets/img/mojo.jpg" alt="imagen" [routerLink]= "['/detalle',103]" id="imagen"></td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',111]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',103]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>


  </tbody>
</table>

<table  style=" text-align: center;">
  <!--  <thead style="border: 1pt solid #c8e6c9;" >
      <tr >
        <!--149. Ocultando botones en las plantillas según el role del usuario

        <th style="color:#4b830d;">{{ 'MENU.lbl_nombre' | translate }}</th>
        <th style="color:#4b830d;"></th>
        <th style="color:#4b830d;">Origen</th>
      </tr>
    </thead>-->

  <tbody>
    <!--<tr *ngFor= "let aceituna of aceitunas">


      <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">{{aceituna.nombre}}</td>
      <td>
        <div style="vertical-align:middle; color: #666633; text-align: center;"><img [src]='aceituna.foto' alt="imagen"></div>
        <div><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',aceituna.id]" style="margin-bottom:5px;">Ampliar</button></div>
      </td>


      <td style="vertical-align:middle;color: #666633; text-align: center; padding:10px"><img [src]='aceituna.origen' alt="imagen"></td>-->


      <tr><!--*ngFor="let elaborado of _elaborados"-->
          <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_salsa' | translate }}</td>

          <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_aragon' | translate }}</td><!--{{ elaborado.nombre }}-->
        </tr>
        <tr>
          <td><input type="image" src="../assets/img/salsa.jpg" alt="imagen" [routerLink]= "['/detalle',104]" id="imagen"></td>
          <td><input type="image" src="../assets/img/aragon.JPG" alt="imagen" [routerLink]= "['/detalle',105]" id="imagen"></td>
        </tr>
        <tr>
          <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',104]" style="margin-bottom:50px">Ampliar</button></td>

          <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',105]" style="margin-bottom:50px">Ampliar</button></td>
        </tr>


  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_arbequina' | translate }}</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_jaen' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td>
          <input type="image" src="../assets/img/arbequinas.jpg" alt="imagen" [routerLink]= "['/detalle',106]" id="imagen">
          </td>

        <td>
          <input type="image" src="../assets/img/jaen.JPG" alt="imagen" [routerLink]= "['/detalle',107]" id="imagen">
        </td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',106]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',107]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>


  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">Kimbitos</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_altra' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td>
          <input type="image" src="../assets/img/kimbo.JPG" alt="imagen" [routerLink]= "['/detalle',108]" id="imagen">
          </td>

        <td>
          <input type="image" src="../assets/img/altra.JPG" alt="imagen" [routerLink]= "['/detalle',109]" id="imagen">
          </td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',108]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',109]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>
  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_caspe' | translate }}</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_manzanilla' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td>
          <input type="image" src="../assets/img/caspe.JPG" alt="imagen" [routerLink]= "['/detalle',110]" id="imagen">
          </td>

        <td>
          <input type="image" src="../assets/img/anchoa.jpg" alt="imagen" [routerLink]= "['/detalle',102]" id="imagen">
          </td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',110]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',102]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>


  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_pepi' | translate }}</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_gaz' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td>
          <input type="image" src="../assets/img/pepi.JPG" alt="imagen" [routerLink]= "['/detalle',112]" id="imagen">
          </td>

        <td>
          <input type="image" src="../assets/img/gazpacha.JPG" alt="imagen" [routerLink]= "['/detalle',113]" id="imagen">
          </td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',112]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',113]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>


  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_alo' | translate }}</td>

        <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_muerta' | translate }}</td><!--{{ elaborado.nombre }}-->
      </tr>
      <tr>
        <td>
          <input type="image" src="../assets/img/alorena.JPG" alt="imagen" [routerLink]= "['/detalle',114]" id="imagen">
          </td>

        <td>
          <input type="image" src="../assets/img/muerta.JPG" alt="imagen" [routerLink]= "['/detalle',115]" id="imagen">
          </td>
      </tr>
      <tr>
        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',114]" style="margin-bottom:50px">Ampliar</button></td>

        <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',115]" style="margin-bottom:50px">Ampliar</button></td>
      </tr>


  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
      <tr><!--*ngFor="let elaborado of _elaborados"-->
      <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">{{ 'ACEITUNAS.lbl_ber' | translate }}</td>
<td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">Ocal</td>
    </tr>
    <tr>
      <td>
        <input type="image" src="../assets/img/beren.JPG" alt="imagen" [routerLink]= "['/detalle',116]" id="imagen">
        </td>
      <td>
        <input type="image" src="../assets/img/okal.JPG" alt="imagen" [routerLink]= "['/detalle',117]" id="imagen">
        </td>
    </tr>
    <tr>
      <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',116]" style="margin-bottom:50px">Ampliar</button></td>
      <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',117]" style="margin-bottom:50px">Ampliar</button></td>
    </tr>


  </tbody>
</table>

<table  style=" text-align: center;">

  <tbody>
    <tr><!--*ngFor="let elaborado of _elaborados"-->
      <tr><!--*ngFor="let elaborado of _elaborados"-->
      <td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">Yeye</td>
<td style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; ">Morada</td>
    </tr>
    <tr>
      <td>
        <input type="image" src="../assets/img/yeye.JPG" alt="imagen" [routerLink]= "['/detalle',118]" id="imagen">
        </td>
      <td>
        <input type="image" src="../assets/img/morada.JPG" alt="imagen" [routerLink]= "['/detalle',119]" id="imagen">
        </td>
    </tr>
    <tr>
      <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',118]" style="margin-bottom:50px">Ampliar</button></td>
      <td><button type="button" class="btn btn-success btn-sm" [routerLink]= "['/detalle',119]" style="margin-bottom:50px">Ampliar</button></td>
    </tr>


  </tbody>
</table>

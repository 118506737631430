import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salazones',
  templateUrl: './salazones.component.html',
  styleUrls: ['./salazones.component.css']
})
export class SalazonesComponent implements OnInit {

  imageObject: Array<object> = [{
        image: '../assets/img/arenques.jpg',
        thumbImage: '../assets/img/arenques.jpg',
        alt: 'alt of image',
        title: ''
    }, {
        image: '../assets/img/mojama.jpg', // Support base64 image
        thumbImage: '../assets/img/mojama.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: 'Image alt' //Optional: You can use this key if want to show image with alt
    }, {
        image: '../assets/img/salmon.jpg', // Support base64 image
        thumbImage: '../assets/img/salmon.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: 'Image alt' //Optional: You can use this key if want to show image with alt
    }

];
  constructor() { }

  ngOnInit(): void {
  }

}

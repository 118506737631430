<table class="table-striped" style="text-align: center;"> <!--#cfff95;-->
    <thead >
      <tr >


      </tr>
    </thead>

  <tbody>
        <div *ngIf="href=='/detalle/1'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/croquetas.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_croquetas' | translate }}</div><!--{{ _elaborados[0].descripcion }}-->
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/2'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px;">
          <img src="../assets/img/sanfaina.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_sanfaina' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/3'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/llauna.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_llauna' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/4'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/esqueixada.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_esqueixada' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/5'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/boquerones.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_boqueron' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 7
                </div>
                <div><img src="../assets/img/tarrina7.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 8
              </div>
              <div><img src="../assets/img/tarrina8.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>

        <div *ngIf="href=='/detalle/6'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/salpicon.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_salpicon' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/7'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/escalivada.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_escalivada' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/8'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/bunuelos.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_bunuelos' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/9'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/banderillas.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_banderillas' | translate }}</div>
          <!--envases-->



          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <div *ngIf="href=='/detalle/10'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/anchoas2.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_anchoas' | translate }}</div>
          <!--envases-->

          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>

        <div *ngIf="href=='/detalle/11'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/bunuelos.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_bunuelos' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>

        <div *ngIf="href=='/detalle/12'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/carpa.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_carpa' | translate }}</div>
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px"></div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 7
                </div>
                <div><img src="../assets/img/tarrina7.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 8
                </div>
                <div><img src="../assets/img/tarrina8.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>


            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>

        <div *ngIf="href=='/detalle/13'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/ensaladilla.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px"></div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>

              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>

            </tbody>
          </table>
          <!--envases-->
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/elaborados']">{{ 'MENU.lbl_volver' | translate }}</button>
        </div>
        <!--ACEITUNAS-->
        <div *ngIf="href=='/detalle/100'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/abuela.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_abuela' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/101'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/anchoa.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_anchoa' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/102'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/manzanilla.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_manzanilla' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/103'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/mojo.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_mojo' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/104'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/salsa.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_salsa' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/105'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/aragon.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_aragon' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/106'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/arbequinas.jpg" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_arbequina' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/107'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/jaen.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_jaen' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/108'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/kimbo.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_kimbo' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/109'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/altra.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_altra' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/110'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/caspe.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_caspe' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/111'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/coctel.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_coctel' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/112'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/pepi.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_pepi' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/113'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/gazpacha.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_gazpa' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/114'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/alorena.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_alo' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/115'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/muerta.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_muerta' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
        <div *ngIf="href=='/detalle/116'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/beren.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_beren' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>

        <div *ngIf="href=='/detalle/117'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/okal.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">Ocal</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>

        <div *ngIf="href=='/detalle/118'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/yeye.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_yeye' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>

        <div *ngIf="href=='/detalle/119'" style="vertical-align:middle; color: #666633; text-align: center; padding:10px">
          <img src="../assets/img/morada.JPG" alt="imagen" style="height:auto;">
          <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'DETALLE.dte_morada' | translate }}</div>
          <!--envases-->
          <p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_envase0' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</p>

          <table *ngIf="mostrar==true"  style="text-align: center;">
            <tbody>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 1
                </div>
                <div><img src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 2
              </div>
              <div><img src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 3
                </div>
                <div><img src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
                </td>
                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 4
                </div>
                <div><img src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 5
                </div>
                <div><img src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 6
              </div>
              <div><img src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
              <tr style="background-color:white">

                <td>
                  <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                  Tarrina 9
                </div>
                <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
              </td>
              <td>
                <div style="vertical-align:middle;color: #666633; text-align: center; padding:10px; font-weight:bold;">
                Tarrina 10
              </div>
              <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
              </td>
              </tr>
            </tbody>
          </table>
          <!--envases-->
          <div style="vertical-align:middle;color: #666633; text-align: center; font-weight: bold; padding:10px">
          <button type="button" class="btn btn-success btn-sm my-3" [routerLink]= "['/aceitunas']">{{ 'MENU.lbl_volver' | translate }}</button>
          </div>
        </div>
  </tbody>
</table>

<footer class = "footer rounded-top text-center">
  <div class="cointainer py-2" >
    <p class="text-white my-2">
      {{ 'MENU.lbl_mensaje' | translate }}
      <div class="text-white my-2"> <a href='tel:93 541 47 78' style="font-weight:bold;">93 541 47 78</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://api.whatsapp.com/send?phone=34646750792" style="font-weight:bold;"> {{encargo.telefono}}</a> &nbsp;<img src="../assets/img/whatsapp.png" alt="imagen" width="24px" height="24px"></div>
      <div></div>
      <!-- &nbsp;&nbsp;&nbsp;&nbsp; <a href="https://www.facebook.com/Pesca-Salada-Pepi-104884444628210" style="font-weight:bold;" target="_blank">Pesca Salada Pepi</a>&nbsp; <img src="../assets/img/facebook.png" alt="imagen" width="24px" height="24px"> -->

  </div>
</footer>

import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl:'./footer.component.html',
  styleUrls:['./footer.component.css']
})

export class FooterComponent {
  public encargo:any = {mensaje: 'Encarga tu pedido a domicilio o para recoger en:', telefono:'646 75 07 92'};
}

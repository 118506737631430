import { Component, OnInit } from '@angular/core';
import { Elaborado } from './elaborado';

@Component({
  selector: 'app-elaborados',
  templateUrl: './elaborados.component.html',
  styleUrls: ['./elaborados.component.css']
})
export class ElaboradosComponent implements OnInit {

  public _elaborados:Elaborado[]=[

    {id:1,nombre:"Croquetas de bacalao",descripcion:"Croquetas realizadas con nuestro mejor bacalao, totalmente caseras. Listas para freír a fuego medio-alto durante 2-3 minutos", foto:"../assets/img/croquetas.jpeg"},
    {id:2,nombre:"Bacalao con sanfaina",descripcion:"Bacalao en sanfaina casero listo para comer con un golpe de microondas", foto:"foto"},
    {id:3,nombre:"Bacalao a la llauna",descripcion:"Nuestro lomo de bacalao rebozado y acompañado de pimentón y ajo. Listo para comer calentando al microondas. Se puede acompañar con alubias.", foto:"foto"},
    {id:4,nombre:"Esqueixada",descripcion:"", foto:"foto"},
    {id:5,nombre:"Boquerones en vinagre",descripcion:"Boquerones de mercado elaborados con paciencia, agua, sal y vinagre", foto:'../assets/img/boquerones.jpg'},
    {id:6,nombre:"Salpicón",descripcion:"", foto:"foto"},
    {id:7,nombre:"Escalivada",descripcion:"", foto:"foto"},
    {id:8,nombre:"Filete rebozado",descripcion:"", foto:"foto"},
    {id:9,nombre:"Banderillas",descripcion:"", foto:"foto"}

  ];


  constructor() { }

  ngOnInit(): void {
  console.log("traça")
  console.log(this._elaborados[0])
  }

  get elaborados(): string {
        return this.elaborados;
    }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {HeaderComponent} from './header/header.component';
import { RouterModule,Routes } from '@angular/router';
import { AceitunasComponent } from './aceitunas/aceitunas.component';
import { FooterComponent } from './footer/footer.component';
import { PortadaComponent } from './portada/portada.component';
import { ElaboradosComponent } from './elaborados/elaborados.component';
import { CommonModule } from '@angular/common';
import { DetalleComponent } from './elaborados/detalle.component';
import { ContactoComponent } from './contacto/contacto.component';
import { BacalaoComponent } from './bacalao/bacalao.component';
import { ConservasComponent } from './conservas/conservas.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PedidosComponent } from './pedidos/pedidos.component';
import { SalazonesComponent } from './salazones/salazones.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


const routes: Routes=[

  {path: '',redirectTo:'/inicio',pathMatch:'full'},
  {path: 'inicio',component:PortadaComponent},
  {path: 'contacto', component:ContactoComponent},
  {path: 'aceitunas', component:AceitunasComponent},
  {path: 'portada', component:PortadaComponent},
  {path: 'elaborados', component:ElaboradosComponent},
  {path: 'detalle', component:DetalleComponent},
  {path: 'detalle/:id', component:DetalleComponent},
  {path: 'bacalao', component:BacalaoComponent},
  {path: 'conservas', component:ConservasComponent},
  {path: 'pedidos', component:PedidosComponent},
  {path: 'salazones', component:SalazonesComponent}

];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AceitunasComponent,
    FooterComponent,
    PortadaComponent,
    ElaboradosComponent,
    DetalleComponent,
    ContactoComponent,
    BacalaoComponent,
    ConservasComponent,
    PedidosComponent,
    SalazonesComponent

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    CommonModule,
    NgImageSliderModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
     }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

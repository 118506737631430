<p style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_llamar' | translate }}</p>

<div class="" >



<table >
  <tr>
    <td><img src="../assets/img/1.png" alt="image" style="padding:10px; " width="100px" height="100px"></td>
    <td><a style="vertical-align:middle;color: #666633;  padding:10px">{{ 'MENU.lbl_llamar1' | translate }}</a><a href="https://api.whatsapp.com/send?phone=34646750792" style="font-weight:bold;" >646 75 07 92</a>&nbsp;<img src="../assets/img/whatsapp.png" alt="imagen" width="24px" height="24px">&nbsp;<a style="vertical-align:middle;color: #666633;  padding:10px">{{ 'MENU.lbl_llamar4' | translate }}</a><a href='tel:93 541 47 78' style="font-weight:bold;">93 541 47 78</a></td>
  </tr>
<br/>

  <tr>
    <td><img src="../assets/img/2.png" alt="image" style="padding:10px; " width="100px" height="100px"></td>
    <td><a style="vertical-align:middle;color: #666633;  padding:10px">{{ 'MENU.lbl_envase' | translate }} <button type="button" class="btn btn-link"  style="font-weight:bold;" (click)="envases()">{{ 'MENU.lbl_envase2' | translate }}</button>{{ 'MENU.lbl_envase3' | translate }}</a></td>

  </tr>
  <br/>
  </table>
  <!--envases-->

  <table *ngIf="mostrar==true"   align="left" >
    <tbody>

      <tr style="background-color:white">
        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 1
        </div>
        <div><img id="imagen" src="../assets/img/oliva1.jpg" alt="image" style="padding:10px"></div>
        </td>
        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 2
        </div>
        <div><img id="imagen" src="../assets/img/oliva2.jpg" alt="image" style="padding:10px"></div>
        </td>
        </tr>
        <tr style="background-color:white">
        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 3
        </div>
        <div><img id="imagen" src="../assets/img/tarrina3.JPG" alt="image" style="padding:10px"></div>
        </td>
        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 4
        </div>
        <div><img  id="imagen" src="../assets/img/tarrina4.JPG" alt="image" style="padding:10px"></div>
      </td>

      </tr>
      <tr style="background-color:white">

        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 5
        </div>
        <div><img  id="imagen" src="../assets/img/tarrina5.JPG" alt="image" style="padding:10px"></div>
      </td>
      <td>
        <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
        Tarrina 6
      </div>
      <div><img  id="imagen" src="../assets/img/tarrina6.JPG" alt="image" style="padding:10px"></div>
      </td>
      </tr>
      <tr style="background-color:white">

        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 7
        </div>
        <div><img  id="imagen" src="../assets/img/tarrina7.JPG" alt="image" style="padding:10px"></div>
      </td>
      <td>
        <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
        Tarrina 8
      </div>
      <div><img  id="imagen" src="../assets/img/tarrina8.JPG" alt="image" style="padding:10px"></div>
      </td>
      </tr>
      <tr style="background-color:white">

        <td>
          <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
          Tarrina 9
        </div>
        <div><img  id="imagen" src="../assets/img/tarrina9.JPG" alt="image" style="padding:10px"></div>
      </td>
      <td>
        <div style="vertical-align:middle;color: #666633; text-align: justify; padding:10px; font-weight:bold;">
        Tarrina 10
      </div>
      <div><img  id="imagen" src="../assets/img/tarrina10.JPG" alt="image" style="padding:10px"></div>
      </td>
      </tr>
    </tbody>
  </table >

  <!--envases-->
  <table  >
    <tr>
      <td><img src="../assets/img/3.png" alt="image" style="padding:10px; " width="100px" height="100px"></td>
      <td><a style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_llamar3' | translate }}</a></td>
    </tr>

    <br/>
    <tr>
      <td><img src="../assets/img/4.png" alt="image" style="padding:10px; " width="100px" height="100px"></td>
      <td><a style="vertical-align:middle;color: #666633; text-align: center; padding:10px">{{ 'MENU.lbl_llamar2' | translate }}</a> &nbsp;<img src="../assets/img/pagos.png" alt="imagen" width="15%"></td>
    </tr>
  </table>
</div>
<br>
<br>
<!-- <div class="card" style="margin-bottom: 20px;" align="justify">
  <h5 class="card-header"  style="background-color: #a5b368; text-align: center; "><b>{{ 'MENU.lbl_card_title' | translate }}</b></h5>
  <div class="card-body">
    <h5 class="card-title" style="color: #465019;"><b>{{ 'MENU.lbl_card_subtitle' | translate }}</b></h5>
    <p class="card-text" style="color: #465019;">{{ 'MENU.lbl_card_description' | translate }}</p>

    <a routerLink="/pedidos" class="btn btn-outline-success btn-lg " style="color:#198754; font-weight: bold;display: flex;
  justify-content: center; ">Parada web</a>
  </div>
</div> -->

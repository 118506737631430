
<h2 style="color: #666633;margin:5px; text-align:center"><b>{{ 'MENU.lbl_sitio' | translate }}</b></h2>
<br>

<table class="table-bordered table-striped" style="vertical-align:middle;color: #666633; padding:10px; margin: 0 auto;" >
  <thead>
    <p style="color: #666633;margin:5px; text-align:center;"><b>{{ 'MENU.lbl_horario' | translate }}</b></p>
  </thead>
  <tr>
    <td>{{ 'MENU.lbl_lunes' | translate }}</td>
    <td style="margin:10px; padding:5px">&nbsp;&nbsp;&nbsp;   De 08:15 a 14:00</td>

  </tr>
  <tr>
    <td>{{ 'MENU.lbl_martes' | translate }}</td>
    <td style="margin:10px; padding:5px">&nbsp;&nbsp;&nbsp;    De 08:15 a 21:00</td>
  </tr>
  <tr>
    <td>{{ 'MENU.lbl_miercoles' | translate }} &nbsp;</td>
    <td style="margin:10px; padding:5px">&nbsp;&nbsp;&nbsp;   De 08:15 a 21:00</td>
  </tr>
  <tr>
    <td>{{ 'MENU.lbl_jueves' | translate }}</td>
    <td style="margin:10px; padding:5px">&nbsp;&nbsp;&nbsp;   De 08:15 a 21:00</td>
  </tr>
  <tr>
    <td>{{ 'MENU.lbl_viernes' | translate }}</td>
    <td style="margin:10px; padding:5px">&nbsp;&nbsp;&nbsp;   De 08:15 a 21:00</td>
  </tr>
  <tr>
    <td>{{ 'MENU.lbl_sabado' | translate }}</td>
    <td style="margin:10px; padding:5px">&nbsp;&nbsp;&nbsp;   De 08:15 a 15:00</td>
  </tr>
</table>
<br>
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d222.75037631301987!2d2.002236540200122!3d41.30973624068176!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49d5382517641%3A0x40ffa5094feb8fea!2sPesca%20Salada%20Pepi!5e0!3m2!1sca!2ses!4v1598002815130!5m2!1sca!2ses"  frameborder="0" style="border:0; display:block; margin-right:auto; margin-left:auto;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
<br>
<p style="vertical-align:middle;color: #666633; text-align: center; padding:10px"> {{ 'MENU.lbl_insta' | translate }}<img src="../assets/img/insta.png" alt="imagen" width="24px" height="24px">&nbsp; <a href="https://www.instagram.com/pescasaladapepi/" target="_blank">{{ 'MENU.lbl_insta2' | translate }}</a></p>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  title = 'pesca-salada-pepi';




  constructor(public translate: TranslateService) {
    translate.setDefaultLang('es');
    translate.use('es');
  }

  setLanguage(language: string) {
    this.translate.use(language);
  }





}

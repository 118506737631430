import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.css']
})
export class PortadaComponent implements OnInit {


  imageObject: Array<object> = [{
        image: '../assets/img/boquerones.jpg',
        thumbImage: '../assets/img/boquerones.jpg',
        alt: '',
        title: ''
    }, {
        image: '../assets/img/croquetas.JPG', // Support base64 image
        thumbImage: '../assets/img/croquetas.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/abuela.jpg', // Support base64 image
        thumbImage: '../assets/img/abuela.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/arbequinas.jpg', // Support base64 image
        thumbImage: '../assets/img/arbequinas.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/esqueixada.JPG', // Support base64 image
        thumbImage: '../assets/img/esqueixada.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/llauna.JPG', // Support base64 image
        thumbImage: '../assets/img/llauna.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/altra.JPG', // Support base64 image
        thumbImage: '../assets/img/altra.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/caspe.JPG', // Support base64 image
        thumbImage: '../assets/img/caspe.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/anchoa.jpg', // Support base64 image
        thumbImage: '../assets/img/anchoa.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/aragon.JPG', // Support base64 image
        thumbImage: '../assets/img/aragon.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/deshu.JPG', // Support base64 image
        thumbImage: '../assets/img/deshu.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/finas.JPG', // Support base64 image
        thumbImage: '../assets/img/finas.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/gazpacha.JPG', // Support base64 image
        thumbImage: '../assets/img/gazpacha.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/kimbo.JPG', // Support base64 image
        thumbImage: '../assets/img/kimbo.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/morada.JPG', // Support base64 image
        thumbImage: '../assets/img/morada.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/okal.JPG', // Support base64 image
        thumbImage: '../assets/img/okal.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/perdi.JPG', // Support base64 image
        thumbImage: '../assets/img/perdi.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/yeye.JPG', // Support base64 image
        thumbImage: '../assets/img/yeye.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/pepi.JPG', // Support base64 image
        thumbImage: '../assets/img/pepi.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/alorena.JPG', // Support base64 image
        thumbImage: '../assets/img/alorena.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/mojo.jpg', // Support base64 image
        thumbImage: '../assets/img/mojo.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/colas.JPG', // Support base64 image
        thumbImage: '../assets/img/colas.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/tiras.JPG', // Support base64 image
        thumbImage: '../assets/img/tiras.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/filetes.JPG', // Support base64 image
        thumbImage: '../assets/img/filetes.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/lomo.JPG', // Support base64 image
        thumbImage: '../assets/img/lomo.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/anchoas.JPG', // Support base64 image
        thumbImage: '../assets/img/anchoas.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/escalivada.JPG', // Support base64 image
        thumbImage: '../assets/img/escalivada.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/salpicon.JPG', // Support base64 image
        thumbImage: '../assets/img/salpicon.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/sanfaina.jpg', // Support base64 image
        thumbImage: '../assets/img/sanfaina.jpg', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/bunuelos.JPG', // Support base64 image
        thumbImage: '../assets/img/bunuelos.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/tacos.JPG', // Support base64 image
        thumbImage: '../assets/img/tacos.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/llauna.JPG', // Support base64 image
        thumbImage: '../assets/img/llauna.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/ensaladilla.JPG', // Support base64 image
        thumbImage: '../assets/img/ensaladilla.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    },
    {
        image: '../assets/img/carpa.JPG', // Support base64 image
        thumbImage: '../assets/img/carpa.JPG', // Support base64 image
        title: '', //Optional: You can use this key if want to show image with title
        alt: '' //Optional: You can use this key if want to show image with alt
    }

];
  constructor() { }

  ngOnInit(): void {
  }

}


<div style="vertical-align:middle;color: #666633; text-align: center;  ">
  {{ 'BACALAO.lbl_1' | translate }}
</div>
<br>
<div style="vertical-align:middle;color: #666633; text-align: center;  ">
  {{ 'BACALAO.lbl_8' | translate }}
</div>
<br>
<div style="vertical-align:middle;color: #666633; text-align: center;  ">
  {{ 'BACALAO.lbl_9' | translate }}
</div>

<br>
  <ul style="vertical-align:middle;color: #666633;  margin-right: auto; margin-left: auto; ">
    <li>{{ 'BACALAO.lbl_2' | translate }}</li>
    <li>{{ 'BACALAO.lbl_3' | translate }}</li>
    <li>{{ 'BACALAO.lbl_4' | translate }}</li>
    <li>{{ 'BACALAO.lbl_5' | translate }}</li>
    <li>{{ 'BACALAO.lbl_7' | translate }}</li>
    <li>{{ 'BACALAO.lbl_6' | translate }}</li>
  </ul>

<br>



<table  style=" text-align: center;">

  <tbody>

      <tr>
        <td><img src="../assets/img/lomo.JPG" alt="imagen" ></td>
        <td><img src="../assets/img/filetes.JPG" alt="imagen" ></td>
      </tr>
      <tr>
        <td><img src="../assets/img/tiras.JPG" alt="imagen" ></td>
        <td><img src="../assets/img/colas.JPG" alt="imagen" ></td>
      </tr>
  </tbody>
</table>

<nav class="navbar navbar-expand-lg navbar-light" style="background-color: #a5b368">
  <a class="navbar-brand" href="#" style="color: #465019; font-weight: bold; font-size:20px;">{{title}}</a>
  <button class="navbar-toggler" type="button" style="background-color: #c4d181;" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/elaborados"   style="color:#465019; font-weight: bold; ">{{ 'MENU.lbl_elaborados' | translate }}</a>
      </li>
      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/aceitunas"  style="color: #465019; font-weight: bold; ">{{ 'MENU.lbl_aceitunas' | translate }}</a>
      </li>
      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/bacalao"  style="color: #465019; font-weight: bold; ">{{ 'MENU.lbl_bacalao' | translate }}</a>
      </li>
      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/salazones" style="color: #465019; font-weight: bold; ">{{ 'MENU.lbl_salazones' | translate }}</a>
      </li>
      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/conservas" style="color: #465019; font-weight: bold; ">{{ 'MENU.lbl_conservas' | translate }}</a>
      </li>
      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/pedidos" style="color: #465019; font-weight: bold; ">{{ 'MENU.lbl_pedido' | translate }}</a>
      </li>
      <li class="nav-item" routerLinkActive="activo">
        <a class="nav-link" routerLink="/contacto" style="color: #465019; font-weight: bold; ">{{ 'MENU.lbl_contacto' | translate }}</a>
      </li>
    </ul>

  </div>

</nav>
